export interface Route {
  text: string
  path: string
  mobile?: boolean
  subRouting?: Array<Route>
}

const routingEN: Array<Route> = [
  {
    text: 'News',
    path: '/en/news'
  },
  {
    text: 'Didactics',
    path: '/en/didactics',
    subRouting: [
      {
        text: 'Consultations',
        path: '/en/didactics/consultations'
      },
      {
        text: 'Instructions',
        path: '/en/didactics/instructions'
      }
    ]
  },
  {
    text: 'Employees',
    path: '/en/employees'
  },
  {
    text: 'Offer and Projects',
    path: '/en/offer',
    subRouting: [
      {
        text: 'Offer',
        path: '/en/offer'
      },
      {
        text: 'Projects',
        path: '/en/projects'
      },
      {
        text: 'Download',
        path: '/en/download'
      },
    ]
  }
]
const routingPL: Array<Route> = [
  {
    text: 'Aktualności',
    path: '/aktualnosci'
  },
  {
    text: 'Dydaktyka',
    path: '/dydaktyka',
    subRouting: [
      {
        text: 'Konsultacje',
        path: '/dydaktyka/konsultacje'
      },
      {
        text: 'Instrukcje',
        path: '/dydaktyka/instrukcje'
      }
    ]
  },
  {
    text: 'Pracownicy',
    path: '/pracownicy'
  },
  {
    text: 'Oferta i Projekty',
    path: '/oferta',
    subRouting: [
      {
        text: 'Oferta',
        path: '/oferta'
      },
      {
        text: 'Projekty',
        path: '/projekty'
      },
      {
        text: 'Do pobrania',
        path: '/pobieranie'
      },
    ]
  }
]

export const useRouting = (isEn?: boolean) => {
  return isEn ? routingEN : routingPL
}
