import * as React from 'react'
import { ReactElement } from 'react'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import Navbar from '../Navbar'
import Meta from '../Meta'
import { ENPL } from './ENPL'
import { Name } from './name'
import { Logo } from './logo'
import { Footer } from './footer'
import 'typeface-crimson-text'
import bg1 from '../../images/lava_mug.jpg'
import bg2 from '../../images/saw.jpg'
import bg3 from '../../images/lego.jpg'
import bg4 from '../../images/board.jpg'

const useStyles = makeStyles({
  '@global': {
    body: {
      padding: '0px',
      margin: '0',
      minHeight: '100vh',
      position: 'relative'
    },
    html: {
      fontFamily: `"Candara"`,
      position: 'relative',
      minHeight: '100vh',
      margin: '0px',
      padding: '0px',

      backgroundPosition:
        'top 50% left calc(50% - 550px), top right, bottom left, bottom right',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundColor: 'white'
    }
  },
  logo: {
    position: 'relative',
    height: '120px',
    display: 'flex',
    lineHeight: '120px',
    backgroundColor: 'white',
    width: '1010px',
    ['@media (max-width: 1010px)']: {
      width: '100%',
      margin: '0'
    }
  },
  mainContainer: {
    width: '1010px',
    margin: 'auto',
    backgroundColor: 'white',
    minHeight: '100vh',
    position: 'relative',
    ['@media (max-width: 1010px)']: {
      width: '100%',
      margin: '0'
    }
  },
  child: {
    paddingBottom: '160px',
    ['@media(max-width: 1010px)']: {
      paddingBottom: '460px'
    }
  },
  columns: {
    display: 'flex',
    position: 'relative'
  },
  leftNav: {
    position: 'fixed',
    height: '100vh',
    width: 'calc(50% - 505px)'
  },
  rightNav: {
    position: 'fixed',
    height: '100vh',
    width: 'calc(50% - 505px)',
    right: '0'
  },
  topLeft: {
    ['@media (min-width: 1010px)']: {
      width: '100%',
      height: '50%',
      backgroundImage: `url(${bg1})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  },
  bottomLeft: {
    ['@media (min-width: 1010px)']: {
      width: '100%',
      height: '50%',
      backgroundImage: `url(${bg2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  },

  topRight: {
    ['@media (min-width: 1010px)']: {
      width: '100%',
      height: '50%',
      backgroundImage: `url(${bg3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  },
  bottomRight: {
    ['@media (min-width: 1010px)']: {
      width: '100%',
      height: '50%',
      backgroundImage: `url(${bg4})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  }
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#a34313',
      light: '#c56536',
      dark: '#923202'
    }
  }
})

interface Props {
  children: any
  actualTab?: string
  isEn?: boolean
}

const Layout = ({ children, actualTab, isEn }: Props): ReactElement => {
  const styles = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Meta title={actualTab} />
      <div className={styles.columns}>
        <div className={styles.leftNav}>
          <div className={styles.topLeft} />
          <div className={styles.bottomLeft} />
        </div>
        <main className={styles.mainContainer}>
          <ENPL isEn={isEn} />
          <div className={styles.logo}>
            <Logo />
            <Name isEn={isEn} />
          </div>
          <Navbar isEn={isEn} actualTab={actualTab} />
          <div className={styles.child}>{children}</div>
          <Footer />
        </main>
        <div className={styles.rightNav}>
          <div className={styles.topRight} />
          <div className={styles.bottomRight} />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Layout
