import * as React from 'react'
import { ReactElement, useState } from 'react'
import { useRouting, Route } from '../../hooks/useRouting'
import { Navbutton } from './components/Navbutton'
import { makeStyles } from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core'
import { Link } from 'gatsby'

const classNames = require('classnames')
const buttonHeight = 46

const useStyles = makeStyles(theme => ({
  desktop: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    width: '1010px'
  },
  mobile: {
    width: '100vw'
  },
  mobileMenuButton: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '24px',
    lineHeight: `${buttonHeight}px`,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  mobileButton: {
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    fontSize: '24px',
    lineHeight: `${buttonHeight}px`,
    textDecoration: 'none',
    color: 'black',
    borderTop: '1px white solid'
  },
  mobileButtonText: {
    display: 'flex',
    justifyContent: 'center'
  },
  mobileSubroute: {
    ['@media (min-width: 1010px)']: {
      display: 'none'
    }
  }
}))

interface Props {
  isEn?: boolean
  actualTab?: string
}

interface NavProps {
  routing: Array<Route>
  actualTab?: string
}

const DesktopNav = ({ routing, actualTab }: NavProps): ReactElement => {
  const styles = useStyles()
  return (
    <div className={styles.desktop}>
      {routing.map((route: Route) => {
        return <Navbutton route={route} active={actualTab === route.text} />
      })}
    </div>
  )
}
const MobileNav = ({ routing }: NavProps): ReactElement => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(prev => !prev)
  }
  return (
    <div className={styles.mobile}>
      <div className={styles.mobileMenuButton} onClick={toggleOpen}>
        Menu &#9776;
      </div>
      {open &&
        routing.map((route: Route) => {
          if (route.subRouting) {
            return (
              <>
                {route.subRouting.map(subroute => (
                  <Link to={subroute.path} className={styles.mobileButton}>
                    <div className={styles.mobileButtonText}>
                      {subroute.text}
                    </div>
                  </Link>
                ))}
              </>
            )
          } else {
            return (
              <Link to={route.path} className={styles.mobileButton}>
                <div className={styles.mobileButtonText}>{route.text}</div>
              </Link>
            )
          }
        })}
    </div>
  )
}
const Navbar = ({ isEn, actualTab }: Props): ReactElement => {
  const routing = useRouting(isEn)
  const isMobile = useMediaQuery('(max-width: 1010px)')
  if (isMobile) {
    return <MobileNav routing={routing} actualTab={actualTab} />
  }
  return <DesktopNav routing={routing} actualTab={actualTab} />
}

export default Navbar
