import { makeStyles } from '@material-ui/core'
import * as React from 'react'
const pwrLogo = require('../../images/pwr.png')

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'absolute',
    backgroundColor: 'black',
    bottom: 0,
    height: '150px',
    width: '1010px',
    display: 'flex',
    color: '#C4C4C4',
    ['@media(max-width: 1010px)']: {
      width: '100%',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      height: '460px'
    }
  },
  pwrLogo: {
    height: '70px',
    padding: '40px 15px',
    overflow: 'hidden'
  },
  contact: {
    display: 'flex',
    ['@media(max-width: 1010px)']: {
      display: 'block'
    }
  },
  adreses: {
    display: 'flex',
    ['@media(max-width: 1010px)']: {
      display: 'block'
    }
  },
  b1: {},
  b9: {
    marginLeft: '40px',
    ['@media(max-width: 1010px)']: {
      marginLeft: '0px',
      marginTop: '15px'
    }
  },
  contentWrapper: {
    display: 'flex',
    padding: '10px',
    ['@media(max-width: 1010px)']: {
      display: 'block'
    }
  },
  administrationWrapper: {
    marginLeft: '40px',
    ['@media(max-width: 1010px)']: {
      margin: '0px'
    }
  }
}))

export const Footer = () => {
  const styles = useStyles()
  return (
    <footer className={styles.footer}>
      <img src={pwrLogo} className={styles.pwrLogo} alt="Logo PWr" />
      <div className={styles.contact}>
        <div className={styles.contentWrapper}>
          <div>
            <div style={{ margin: '10px 0', fontWeight: 'bold' }}>Adres</div>
            <div className={styles.adreses}>
              <div className={styles.b1}>
                <div>Budynek B-1</div>
                <div>ul. Smoluchowskiego 25</div>
                <div>50-370 Wrocław</div>
              </div>
              <div className={styles.b9}>
                <div>Budynek B-9</div>
                <div>ul. Łukasiewicza 7/9</div>
                <div>50-371 Wrocław</div>
              </div>
            </div>
          </div>
          <div className={styles.administrationWrapper}>
            <div style={{ margin: '10px 0', fontWeight: 'bold' }}>
              Administracja
            </div>
            <div>Magdalena Niziołek</div>
            <div>tel. 71 320 28 66</div>
            <div>magdalena.niziolek@pwr.edu.pl</div>
            <div>Budynek B-4, pok. 2.22</div>
          </div>
        </div>
        <div></div>
      </div>
    </footer>
  )
}
