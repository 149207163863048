import { makeStyles } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles({
  name: {
    position: 'absolute',
    fontSize: '24px',
    lineHeight: '24px',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    right: '2ch',
    top: '50%',
    transform: 'translateY(-75%)',
    textAlign: 'end',
    ['@media (max-width: 1010px)']: {
      fontSize: '1em',
      lineHeight: '1em'
    },
    ['@media (max-width: 480px)']: {
      width: '100%',
      fontSize: '24px',
      lineHeight: '24px',
      right: 'unset',
      textAlign: 'center'
    }
  }
})
interface Props {
  isEn?: boolean
}
export const Name = ({ isEn }: Props) => {
  const styles = useStyles()
  const namePL =
    'Katedra Inżynierii Elementów Lekkich, Odlewnictwa i Automatyki'
  const nameEn =
    'Department of Lightweight Elements Engineering, Foundry and Automation'
  return <div className={styles.name}>{isEn ? nameEn : namePL}</div>
}
