import * as React from 'react'
import { ReactElement, useState } from 'react'
import { Link } from 'gatsby'
import { Route } from '../../../hooks/useRouting'
import { makeStyles } from '@material-ui/core'
import { SubMenuButton } from './SubMenuButton'

const buttonHeight = 46

interface StylesProps {
  active?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center'
  },
  button: ({ active }: StylesProps) => ({
    backgroundColor: active
      ? theme.palette.primary.light
      : theme.palette.primary.main,
    minWidth: '252.5px',
    height: `${buttonHeight}px`,
    display: 'block',
    justifyContent: 'center',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    textDecoration: 'none',
    color: 'black',
    fontSize: '24px',
    lineHeight: `${buttonHeight}px`
  })
}))

interface Props {
  route: Route
  active?: boolean
}

export const Navbutton = ({ route, active }: Props): ReactElement => {
  const styles = useStyles({ active })
  const [open, setOpen] = useState(false)
  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={styles.root}
    >
      <Link to={route.path} className={styles.button}>
        {route.text}
      </Link>
      {open && route.subRouting && (
        <div className={styles.flexColumn}>
          {route.subRouting.filter(sr => !sr.mobile).map((subRoute: Route, index: number) => {
              return (
                <SubMenuButton
                  route={subRoute}
                  index={index + 1}
                  buttonHeight={buttonHeight}
                />
              )
          })}
        </div>
      )}
    </div>
  )
}
