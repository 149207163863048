import { makeStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import * as React from 'react'

const useStyles = makeStyles(theme => ({
  ENPL: {
    width: '1010px',
    display: 'flex',
    position: 'relative',
    margin: 'auto',
    justifyContent: 'flex-end',
    height: '20px',
    ['@media(max-width: 1010px)']: {
      width: '100%'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  active: { color: theme.palette.primary.light }
}))
interface Props {
  isEn?: boolean
}
export const ENPL = ({ isEn }: Props) => {
  const styles = useStyles()
  return (
    <div className={styles.ENPL}>
      <Link
        to="/"
        activeClassName={styles.active}
        className={styles.link}
        partiallyActive={!isEn}
      >
        PL
      </Link>
      <span>|</span>
      <Link
        to="/en"
        activeClassName={styles.active}
        className={styles.link}
        partiallyActive={isEn}
      >
        EN
      </Link>
    </div>
  )
}
