import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { Route } from '../../../hooks/useRouting'
import { Link } from 'gatsby'
import * as classNames from 'classnames'

interface StylesProps {
  index: number
  buttonHeight: number
}

const useStyles = makeStyles(theme => ({
  subMenuButton: ({ index, buttonHeight }: StylesProps) => ({
    position: 'absolute',
    top: `${index * buttonHeight}px`,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    minWidth: '252.5px',
    height: `${buttonHeight}px`,
    display: 'block',
    textDecoration: 'none',
    color: 'black',
    fontSize: '24px',
    lineHeight: `${buttonHeight}px`,
    zIndex: 999
  })
}))

interface Props {
  route: Route
  index: number
  buttonHeight: number
}
export const SubMenuButton = ({
  route,
  index,
  buttonHeight
}: Props): React.ReactElement => {
  const styles = useStyles({ index, buttonHeight })
  return (
    <div>
      <Link to={route.path} className={styles.subMenuButton}>
        {route.text}
      </Link>
    </div>
  )
}
