import * as React from 'react'
import { Helmet } from 'react-helmet'
const icon = require('../../images/favicon.ico')
interface Props {
    title?: string
}
const Meta = ({title}: Props) => {
    const metadescription = `Katedra Inżynierii Elementów Lekkich, Odlewnictwa i Automatyki na Wydziale Mechanicznym Politechniki Wrocławskiej prowadzi działalność dydaktyczną na uczelni, a także badawczą w licznych projektach krajowych i międzynarodowych. `
    const fullTitle = `${title}${title && ' - '}K54`
    return <Helmet>
        <meta charSet="utf-8" />
        <title>{fullTitle}</title>
        <meta name='description' content={metadescription}/>
        <link rel="icon" type="image/png" href={icon} sizes="16x16" />
    </Helmet>
}

export default Meta