import { makeStyles } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    fontSize: '80px',
    justifyContent: 'center',
    width: '30%',
    lineHeight: '100px',
    ['@media(max-width: 480px)']: {
      display: 'none'
    }
  }
})
export const Logo = () => {
  const styles = useStyles()
  return <div className={styles.logo}>K54</div>
}
